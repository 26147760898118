<template>
  <div class="content">
    <div class="home">
      <a class="logo"></a>
      <div class="logotype"/>
      <p>
        Welcome to <span class="painted">cheqr</span>, an identification system for fab labs and makerspaces.
      </p>
      <button class="actionbutton transition" @click="$router.push('login')">
        Sign in
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hello',
  props: {
  }
}
</script>

<style scoped>
.content {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home {
  max-width: 450px;
  color: rgba(14,7,7,1);
  padding: 1rem;
}
</style>
