<template>
  <div class="footer">
        © {{new Date().getFullYear()}} <a href="https://martin.is">martin s.</a>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
    margin: 1rem;
    position: fixed;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 999;
    user-select: none;
    text-align: right;
    color: rgba(100,100,100,0.35);
}

a {
    color: rgba(100,100,100,0.35);
    text-decoration: none;
}
</style>
