<template>
    <div class="header">
        <div class="header-wrapper max-width">
            <div class="header-logo v-center">
                <span class="logo" />
            </div>
            <div class="navigation anchor m-hide">
                <router-link to="/">
                    <div class="nav-link transition">
                        <span class="nav-icon i-home"/>
                        <span>Home</span>
                    </div>
                </router-link>
                <router-link to="/about">
                    <div class="nav-link transition">
                        <span class="nav-icon i-about"/>
                        <span>About</span>
                    </div>
                </router-link>
                <router-link to="/n68Pw4">
                    <div class="nav-link transition">
                        <span class="nav-icon i-example"/>
                        <span>Example</span>
                    </div>
                </router-link>
                <router-link to="/login">
                    <div class="nav-link active">
                        <span class="nav-icon i-login"/>
                        <span>App</span>
                    </div>
                </router-link>
            </div>
            <div class="navigation anchor m-show">
                <div class="nav-link pointer m-show" @click="mobileMenu = !mobileMenu">
                    <span :class="{ 'menu-open': !mobileMenu, 'menu-close': mobileMenu }"/>
                </div>
                <router-link to="/login">
                    <div class="nav-link active">
                        <span class="nav-icon i-login"/>
                        <span>App</span>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="z-navigation max-width anchor m-show" :class="{ hidden: !mobileMenu }">
                <router-link to="/">
                    <div class="z-nav-link transition">
                        <span class="nav-icon i-home"/>
                        <span>Home</span>
                    </div>
                </router-link>
                <router-link to="/about">
                    <div class="z-nav-link transition">
                        <span class="nav-icon i-about"/>
                        <span>About</span>
                    </div>
                </router-link>
                <router-link to="/n68Pw4">
                    <div class="z-nav-link transition">
                        <span class="nav-icon i-example"/>
                        <span>Example</span>
                    </div>
                </router-link>
            </div>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    props: {
    },
    data() {
        return {
            mobileMenu: false
        };
  },
}
</script>

<style scoped>
.header {
    width: 100vw;
    position: fixed;
    top: 0;
    /*background: rgb(240, 235, 235);*/
    margin: 0;  
    z-index: 999;
    overflow: hidden;
    color: rgba(255,255,255,1);
    font-size: 1rem;
    align-items: center;
    background: rgba(245, 240, 238, 0.75);
    backdrop-filter: blur(0.5rem);
}

.header-wrapper {
    margin: 0 auto;
    padding: 0 1.25rem;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigation {
    display: flex;
    justify-content: flex-end;
    font-size: 0.875rem;
    font-weight: bold;
    text-decoration: none;
    gap: 0.75rem;
}

.z-navigation {
    display: none;
    font-size: 0.875rem;
    font-weight: bold;
    text-decoration: none;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    gap: 0.5rem;
    padding: 0 1.25rem 0.625rem 1.25rem;
}

.anchor a {
    text-decoration: none;
    color: rgb(0,0,0)
}

.nav-link {
    padding: 0.625rem 0.7rem 0.625rem 0.7rem;
    display: flex;
    align-items: center;
    background: linear-gradient(135deg, rgba(255, 171, 102, 0.0) -50%, rgba(244, 117, 255, 0.0) 150%);
    border-radius: 0.5rem;
}

.z-nav-link {
    padding: 0.625rem 0.85rem 0.625rem 0.7rem;
    display: flex;
    align-items: center;
    background: linear-gradient(135deg, rgba(255, 171, 102, 0.0) -50%, rgba(244, 117, 255, 0.0) 150%);
    border-radius: 0.5rem;
}

.nav-link:hover {
    background: linear-gradient(135deg, rgba(255, 171, 102, 0.35) -50%, rgba(244, 117, 255, 0.35) 150%);
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}

.z-nav-link:hover {
    background: linear-gradient(135deg, rgba(255, 171, 102, 0.35) -50%, rgba(244, 117, 255, 0.35) 150%);
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}

.nav-icon {
    display: inline-block;
    height: 1.25rem;
    width: 1.25rem;
    margin: 0 0.5rem 0 0;
}

.active {
    background: rgba(14, 7, 7, 0.90) !important;
    color: rgba(255,255,255,1) !important;
}

.i-home {
    background: url('../assets/icons/home.svg') center center no-repeat;
    background-size: contain;
}

.i-about {
    background: url('../assets/icons/book.svg') center center no-repeat;
    background-size: contain;
}

.i-example {
    background: url('../assets/icons/user-check.svg') center center no-repeat;
    background-size: contain;
}

.i-login {
    background: url('../assets/icons/log-in-white.svg') center center no-repeat;
    background-size: contain;
}

.menu-open {
    display: inline-block;
    height: 1.25rem;
    width: 1.25rem;
    margin: 0;
    background: url('../assets/icons/menu.svg') center center no-repeat;
    background-size: contain;
}

.menu-close {
    display: inline-block;
    height: 1.25rem;
    width: 1.25rem;
    margin: 0;
    background: url('../assets/icons/x.svg') center center no-repeat;
    background-size: contain;
}

.nav-link a {
  color: #2c3e50;
  text-decoration: none;
}

.navigation div.router-link-exact-active {
  background: linear-gradient(135deg, rgb(255, 171, 102) 0%, rgb(244, 117, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo {
    display: block;
    height: 2.25rem;
    width: 8rem;
    background: url('../assets/applogo_black.svg') center center no-repeat;
    background-size: contain;
}

.m-show {
    display: none;
}

.hidden {
    display: none;
}

@media screen and (max-width: 640px) {
  .m-hide {
    display: none !important;
  }

  .m-show {
    display: flex;
  }
}

</style>
