import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
// import { auth } from "../firebasedb";
// import { mapGetters } from "vuex";
// import { store } from "../store";
// import Profile from '../views/Profile.vue' -> Lazy loaded, check alternative in comments

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../components/Login.vue'),
    meta: {
      dashboardRedirect: true,
    }, /*
    beforeEnter(to, from, next) {
      const userState = store.state.user.loggedIn
      console.log(`Router check: Is user logged in: ${userState}`)
      if (userState) {
        next({name: "Admin Dashboard"})
      } else {
        next()
      }
    } */
  },
  {
    path: '/create',
    name: 'Create',
    component: () => import('../components/Create.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/admin',
    alias: ['/dashboard'],
    name: 'Admin Dashboard',
    component: () => import('../components/AdminDashboard.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  { path: '/:id',
    name: 'id',
    component: () => import('../components/Profile.vue'),
    meta: {
      requiresAuth: false,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router