<template>
  <div id="app">
    <router-view :key="$route.path"/>
    <Footer/>
  </div>
</template>


<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Footer,
  }
}
</script>

<style>
html { 
  font-size: 16px;
  transition: 0.25s;
}

@media (max-width: 450px) {
  html { 
    font-size: 14px;
    transition: 0.25s;
  }
}

.transition {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Jetbrains Mono', 'Courier New', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 1rem;
  color: #2c3e50;
}

@import url("../public/fonts/fonts.css");

#app {
  margin: 0;
  padding: 0;
  overflow: scroll;
}

.center {
  max-width: 28.125rem;
  position:none;
  margin: 44vh auto 0 auto;
  height: auto;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.padding {
  padding: 0 2rem;
}

.logo {
  display: block;
  margin: 0 auto;
  width: 200px;
  height: 175px;
  background: url('./assets/cheqr_logo.png') center center no-repeat;
  background-size: contain;
}

.actionbutton {
  font-family: 'Jetbrains Mono', 'Courier New', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 0.8125rem;
  color: #000;
  background: rgba(14, 7, 7, 0.0);
  padding: 0 1.5rem;
  height: 2.5rem;
  outline: transparent;
  border: 2px solid rgba(14, 7, 7, 1);
  margin: 20px auto;
  cursor: pointer;
  border-radius: 0.5rem;
}

.actionbutton:hover {
  color: #fff;
  background: rgba(14, 7, 7, 1);
  transform: scale(1.15)
}

.actionbutton:active {
  color: #fff;
  background: rgba(0,0,0,1.0);
  transform: scale(1.15);
  box-shadow: 0px 0px 0px 0.125rem rgba(245, 240, 238, 1), 0px 0px 0px 0.25rem rgba(255, 65, 44, 0.5);
  /* -webkit-transform: translate(0, -3px);
  transform: translate(0, -3px); */
}

.btnbg {
  background: rgb(255, 99, 229);
  margin: 20px auto;
  width: 200px;
  height: 40px;
  border-radius: 5px;
}

.logotype {
  width: 200px;
  height: 30px;
  margin: 15px auto 20px auto;
  background: url('./assets/logotype2.svg') center center no-repeat;
  background-size: contain;
}

.nav {
  font-size: 1rem;
  position: absolute;
  margin: 20px;
  top: 0%;
  left: 0%;
}

.nav a {
  color: #2c3e50;
  text-decoration: none;
}

.nav a.router-link-exact-active {
  background: linear-gradient(135deg, rgb(255, 171, 102) 0%, rgb(244, 117, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.painted {
  background: linear-gradient(135deg, rgb(255, 171, 102) 0%, rgb(244, 117, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bold {
  font-weight: bold;
}

.v-center {
    display: flex;
    align-items: center;
}

.pointer {
  cursor: pointer;
}

.bas {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.max-width {
  max-width: 60rem;
}

.hidden {
  display: none !important;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 1rem;
}

</style>

