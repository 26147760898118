import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth } from "firebase/auth";

/* eslint-disable no-unused-vars */

// Firebase configuration
const firebaseApp = initializeApp ({
  apiKey: "AIzaSyCOKsevqP4hsA_WcJpZSb8wn8xUcbBSPUU",
  authDomain: "cheqr-4818d.firebaseapp.com",
  projectId: "cheqr-4818d",
  storageBucket: "cheqr-4818d.appspot.com",
  messagingSenderId: "725707610699",
  appId: "1:725707610699:web:dab59ab1c48c3018b0cbce"
});

/*
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LdLIyYgAAAAAPpd--fJSLkjcRZ2P8nosZaeSFd6'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
*/

if (process.browser) {
  const publicKey = '6LdLIyYgAAAAAPpd--fJSLkjcRZ2P8nosZaeSFd6'

  if (process.env.NODE_ENV !== 'production') {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true // Set to true or debug token string during development (self. / window.)
  } 

  const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(publicKey),
    isTokenAutoRefreshEnabled: true,
  })
}

// Initialize Firebase database
const db = getFirestore();
const auth = getAuth();

export { db, auth };

/* eslint-enable no-unused-vars */