/* exported app */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from "./store"

import VueQRCodeComponent from 'vue-qrcode-component'
import QrcodeVue from 'qrcode.vue'

import { auth } from "./firebasedb";
import { onAuthStateChanged } from "firebase/auth";


Vue.component('qr-code', VueQRCodeComponent)
Vue.component('qrcode-vue', QrcodeVue)

/* eslint-disable no-unused-vars */

/* eslint-enable no-unused-vars */

/* List all profiles!

(async () => {
  const querySnapshot = await getDocs(collection(db, "profiles"));
  querySnapshot.forEach((doc) => {
    console.log(`${doc.id} => ${doc.data()}`);
});
})();
*/

/*
async function getDocument (id) {
  const snap = await getDoc(doc(db, 'profiles', id))
  if (snap.exists())
    return snap.data()
  else
    return Promise.reject(Error(`No such profile: ${id}`))
}

console.log("n7Hjx6", getDocument("n7Hjx6"));
*/

Vue.config.productionTip = false;

const user = () => {
  return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (userID) => {
          resolve(userID);
      }, reject);
  });
};

/*
onAuthStateChanged(auth, (user) => {
  store.dispatch("fetchUser", user);
  console.log(auth.getCurrentUser())
});
*/

router.beforeEach(async (to, from, next) => {
  if ( to.meta.requiresAuth ) {
    const userID = await user()
    console.log("Checking for user...")
    if ( !userID ) {
      console.log("1.1: Auth required, none detected.")
      next('login');
    } else {
      store.dispatch("fetchUser", userID);
      console.log(`1.3: Auth accepted. UID: ${userID.uid}`)
      next()
    }
  } else if (to.name == "login") {
    const userID = await user()
    console.log("Login: Checking for user...")
    if ( userID ) {
      router.push({ path: '/dashboard' })
    } else {
      console.log("Login: No user detected.")
      next()
    }
  } else {
    console.log("2.1: No Auth required.")
    next()
  }
})



new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

/* === .htaccess info start ===

<IfModule mod_rewrite.c>
  RewriteRule ^(SUBDIRECTORY_FOO|SUBDIRECTORY_BAR)($|/) - [L]
  RewriteEngine On
  RewriteBase /
  RewriteRule ^index\.html$ - [L]
  RewriteCond %{REQUEST_FILENAME} !-f
  RewriteCond %{REQUEST_FILENAME} !-d
  RewriteCond %{REQUEST_FILENAME} !-l
  RewriteRule . /index.html [L]
</IfModule>

=== .htaccess info end === */