<template>
  <div class="content">
    <Navbar/>
    <div>
      <Hello/>
    </div>
  </div>
</template>

<script>
import Hello from '@/components/Hello.vue'
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'Home',
  components: {
    Hello,
    Navbar
  }
}
</script>

<style scoped>
.content {
  background: rgb(245, 240, 238); /* 245, 240, 238 */
}

.home {
  max-width: 450px;
}
</style>
