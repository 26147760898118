import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      userCredential: null,
      uID: null
    }
  },
  getters: {
    user(state) {
        return state.user
    },
    loggedIn(state) {
        return state.user.loggedIn
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
      console.log(`loggedIn mutation worked. Proof: ${value}`)
    },
    SET_USER(state, userCredential) {
      state.user.userCredential = userCredential;
      state.user.uID = userCredential.uid;
      console.log(`setUser in mutation worked. Proof: ${userCredential}`)
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid,
        });
        console.log(`User succesfully logged in: ${user.email}`)
        // console.log(store.state.user.loggedIn)
      } else {
        commit("SET_USER", null);
        console.log(`No user currently logged in: ${user.email}`)
        console.log(user.loggedIn)
      }
    }
  }
});